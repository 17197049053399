import React, { useEffect } from 'react';
import '../components/ChatContainer.css';
import '../styles.css';

const ChatContainer = () => {
  useEffect(() => {
    const chatContainer = document.querySelector('.chat-container');
    const balloons = chatContainer.querySelectorAll('.txt-balloon-left, .txt-balloon-right');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          balloons.forEach((balloon, index) => {
            setTimeout(() => {
              balloon.classList.add('show');
            }, index * 500); // Retardo para cada globo de texto
          });
          observer.disconnect(); // Deja de observar una vez que se han mostrado los globos
        }
      });
    }, { threshold: 0.5 });

    observer.observe(chatContainer);

    return () => {
      if (observer) observer.disconnect();
    };
  }, []);

  return (
    <div className="chat-container">
      <div className="txt-balloon-left">
        <p><span className="font-bold">Experiencia y entusiasmo: </span>Llevamos años creando soluciones web innovadoras para empresas de todos los tamaños. Nos encanta lo que hacemos y estamos comprometidos con tu éxito.</p>
      </div>
      <div className="txt-balloon-right">
        <p><span className="font-bold">Enfoque personalizado: </span>Trabajamos en estrecha colaboración contigo para comprender tus necesidades y objetivos específicos. Creamos soluciones web personalizadas que se ajustan a tu presupuesto y a tu visión.</p>
      </div>
      <div className="txt-balloon-left">
        <p><span className="font-bold">Tecnologías de vanguardia: </span> Utilizamos las últimas tecnologías y metodologías para crear sitios web rápidos, seguros y escalables.</p>
      </div>
      <div className="txt-balloon-right">
        <p><span className="font-bold">Comunicación constante: </span>Te mantenemos informado en cada paso del proceso, desde la conceptualización hasta el lanzamiento y el mantenimiento de tu sitio web.</p>
      </div>
    </div>
  );
}

export default ChatContainer;
