
import { InputText } from 'primereact/inputtext';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { RadioButton } from "primereact/radiobutton";
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useState, useEffect } from "react";
import './styles.css';
import './fonts/fonts.css';
import { Checkbox } from "primereact/checkbox"
import MenuNav from './components/MenuNav';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { createclientService, createsolicitudService } from './clients';

function App2() {

    let emptynewclient={
        nombre_cliente: "",
        ape_pat_cliente: "",
        ape_mat_cliente: "",
        correo_cliente:"",
        pais_cliente: "",
        celular_cliente: "",
        tipo_cliente: ""
    }
    let emptynewservice={
        id_usuario: "",
        id_cliente: "",
        id_servicio: "",
        estado_solicitud: "",
        otro_servicio:  ""
    }
    const [client, setClient] = useState(emptynewclient);
    const [solicitud, setsolicitud] = useState(emptynewservice);
    const [nameclient, setnameclient] = useState('');
    const [value, setValue] = useState(false);
    const [checked, setChecked] = useState(false);
    const [phonenumber, setPhoneNumber] = useState('');
    const [service, setService] = useState('');
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({
        nombre_cliente: '',
        celular_cliente: '',
        correo_cliente: '',
        privacy_policy: '',
        service:''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = value.startsWith('+') ? value : `+${value}`;
        setClient((prevClient) => ({
          ...prevClient,
          [name]: value,
        }));
        if (name === 'nombre_cliente') {
            setnameclient(value)
            
        }
        if (name === 'celular_cliente') {
            
            setPhoneNumber(formattedValue);
            const phoneNumberParsed = parsePhoneNumberFromString(formattedValue);
            if (phoneNumberParsed && phoneNumberParsed.isValid()) {
              //console.log('Número válido:', phoneNumberParsed.formatInternational());
              //console.log('País:', phoneNumberParsed.country);
              client.pais_cliente=phoneNumberParsed.country;
            } else {
              //console.log('Número inválido');
            }
        }
        //console.log(client)
    }
    const handleSubmit = async (e) => {
        

        // Inicializar errores
        let newErrors = {};

        // Validar campos vacíos
        if (!client.nombre_cliente) {
            newErrors.nombre_cliente = 'Favor de rellenar el campo de nombre.';
        }
        if (!client.celular_cliente) {
            newErrors.celular_cliente = 'Favor de rellenar el campo de número de teléfono.';
        }
        if (!client.correo_cliente) {
            newErrors.correo_cliente = 'Favor de rellenar el campo de correo electrónico.';
        }
        if (!checked) {
            newErrors.privacy_policy = 'Debes aceptar las políticas de privacidad.';
        }
        if (!service) {
            newErrors.service = 'Por favor, selecciona al menos un servicio.';
        } 

        // Actualizar estado de errores
        setErrors(newErrors);

        // Verifica si hay errores
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        // Si no hay errores, continúa con el envío
        setErrors("")
        client.tipo_cliente = "P";
        client.ape_pat_cliente = nameclient;
        client.ape_mat_cliente = nameclient;

        try {
            const response = await createclientService.createclient(client);
            // Maneja la respuesta aquí si es necesario
        } catch (error) {
            console.error('Error al realizar la solicitud:', error);
        }
    };



    const load = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }
    const [isTextareaVisible, setIsTextareaVisible] = useState(false);

    const handleServiceChange = (value) => {
        setService(value);
        
    };
    useEffect(() => {
        //console.log(service);
        if (service === '6') {
            setIsTextareaVisible(true);
        } else {
            setIsTextareaVisible(false);
        }
    }, [service]);
    const handleSubmitservice = async (e) => {
        
        solicitud.id_usuario=1;
        solicitud.estado_solicitud="P";
        solicitud.id_servicio=service;
        //console.log(solicitud)
       

        try {
            const clientid = sessionStorage.getItem('id_cliente');
            console.log(clientid) 
            solicitud.id_cliente=clientid;
            const response=await createsolicitudService.createsolicitud(solicitud)
        } catch (error) {
            console.error('Error al enviar los datos:', error);
        }

    }


    return (
    
<div>
<MenuNav/>

{/* CUADRO CONTACTO*/}

<article className="cuadro justify-content-center">
    <header className="subtitle-header">
        <h3 className="subtitle-contacto">Contacto</h3>
    </header>
    <p className="p-form">Llena el formulario y con gusto te contactamos</p>
        <form className="form-contacto">
            <div className="flex flex-column gap-2">
                <label htmlFor="name" className="text-form-label">Nombre</label>
                <InputText className="input" id="username" aria-describedby="name-help" placeholder="Coloca tu nombre completo" name="nombre_cliente" value={client.nombre_cliente} onChange={handleChange}/>
                {errors.nombre_cliente && <small className="p-error">{errors.nombre_cliente}</small>}
            
            </div>
         
            <div className="flex flex-column gap-2">
                <label htmlFor="email" className="text-form-label">Email</label>
                <InputText className="input" id="email"  name="correo_cliente" value={client.correo_cliente} onChange={handleChange} aria-describedby="email-help" placeholder="Ej. ejemplo@gmail.com"/>
                {errors.correo_cliente && <small className="p-error">{errors.correo_cliente}</small>}
            
            </div>
            
            <div className="flex flex-column gap-2">
                <label htmlFor="phone" className="text-form-label">Número de teléfono</label>
                <InputText className="input" maxLength={13} keyfilter="int" id="phone"  name="celular_cliente" value={client.celular_cliente} onChange={handleChange} aria-describedby="phone-help" placeholder="52 55-0000-0000"/>
                {errors.celular_cliente && <small className="p-error">{errors.celular_cliente}</small>}
            </div>
          
            <div className="radio-group">
                <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                <p className="text-form">Estoy de acuerdo con las políticas de privacidad</p>
            </div>
            
            <label className="text-form-label label-servicio">Servicio</label>
            <div className="columnas-servicio">
                <div className="radio-group">
                    <RadioButton inputId="service1" name="servicio" value="1" onChange={(e) => handleServiceChange(e.value)} checked={service === 'Diseño Web'} />
                    <label htmlFor="service1">Diseño Web</label>
                </div>
                <div className="radio-group">
                    <RadioButton inputId="service2" name="servicio" value="2" onChange={(e) => handleServiceChange(e.value)} checked={service === 'Desarrollo Full Stack'} />
                    <label htmlFor="service2">Desarrollo Full Stack</label>
                </div>
                <div className="radio-group">
                    <RadioButton inputId="service3" name="servicio" value="3" onChange={(e) => handleServiceChange(e.value)} checked={service === 'UX & UI'} />
                    <label htmlFor="service3">UX & UI</label>
                </div>
                <div className="radio-group">
                    <RadioButton inputId="service4" name="servicio" value="4" onChange={(e) => handleServiceChange(e.value)} checked={service === 'Base de datos'} />
                    <label htmlFor="service4">Base de datos</label>
                </div>
                <div className="radio-group">
                    <RadioButton inputId="service5" name="servicio" value="5" onChange={(e) => handleServiceChange(e.value)} checked={service === 'Alojamiento web'} />
                    <label htmlFor="service5">Alojamiento web</label>
                </div>
                <div className="radio-group">
                    <RadioButton inputId="service6" name="servicio" value="6" onChange={(e) => handleServiceChange(e.value)} checked={service === 'Otros'} />
                    <label htmlFor="service6">Otros</label>
                </div> 
                {errors.service && <small className="p-error">{errors.service}</small>}   
            </div>
           
            {isTextareaVisible && (
                    <div >
                        <label htmlFor="otherService" className="flex flex-column text-form-label">Describe tu servicio</label>
                        <InputTextarea autoResize  className="input-des" id="description" aria-describedby="description-help" placeholder="Platícanos acerca de tu proyecto"/>
                    </div>
                )}

                    <Button type="button"  className="button-base" label="Enviar" loading={loading} onClick={(e) => {handleSubmit(); }} /> 
        
        </form>

        <div>
            <img src={process.env.PUBLIC_URL + '/web2-1/web2-1.png'}  className="img-form"></img>
            <img src={process.env.PUBLIC_URL + '/resources/bubble_1.png'}  className="burbuja1"></img>
        </div>

</article>

<Footer/>
             
</div>


    );
    
}

export default App2;