import { urlbasic } from "./urlService";


export const createclientService = {
    
    createclient(clientData) {
        let url = `${urlbasic}api/clientes`;
  
         
        //console.log(paquetData)
        const headers = {
            
            'Content-Type': 'application/json'
            
        };
  
        return fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(clientData) // Convierte los datos a JSON
      })
      .then((response) => {
        
          if (!response.ok) {
              throw new Error('Error al obtener la respuesta del servidor',clientData);
          }
         
          
      
          return response.json();
      })
      .then((data) => {
          console.log(data)
          const idclient=data.data.id_cliente;
          sessionStorage.setItem('idclient', idclient);
          console.log(idclient)
          return data; // Devuelve los datos recibidos 
      })
      .catch(error => console.error('Error al realizar la solicitud:', error, clientData));
    }
  };

  export const createsolicitudService = {
    
    createsolicitud(solicitudData) {
        let url = `${urlbasic}api/solicitudes`;
  
         
        //console.log(paquetData)
        const headers = {
            
            'Content-Type': 'application/json'
            
        };
  
        return fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(solicitudData) // Convierte los datos a JSON
      })
      .then((response) => {
        
          if (!response.ok) {
              throw new Error('Error al obtener la respuesta del servidor',solicitudData);
          }
         
          
      
          return response.json();
      })
      .then((data) => {
          console.log(data)
          
          return data; // Devuelve los datos recibidos 
      })
      .catch(error => console.error('Error al realizar la solicitud:', error, solicitudData));
    }
  };