import React from 'react';
import '../components/Footer.css';
import '../styles.css';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer className='footer'>
            <div className='infoMuza'>
                <Link to ="/">
                    <img id='logoMuzafooter' src="/resources/logo.png" alt="Logo Agencia Muza"/>
                </Link>
                <p>Muza, derechos reservados 2023</p>
                </div>
                    <ul className="footerListaLink">
                    <li className="footerItem"><Link to="/" className='footerLink'>Home</Link></li>
                    <li className="footerItem"><Link to="/#link-elegirnos" className='footerLink'>Somos</Link></li>
                    <li className="footerItem"><Link to="/#link-servicios" className='footerLink'>Servicios</Link></li>
                    <li className="footerItem"><Link to="/proyect" className='footerLink'>Proyectos</Link></li>
                    <li className="footerItem"><Link to="/#section-testimonios" className='footerLink'>Testimonios</Link></li>
                    <li className="footerItem"><Link to="/contact" className='footerLink'>Contacto</Link></li>
                </ul>
                <ul className="footerRedes">
                    <li className="redSocialItem"><a href="https://www.linkedin.com/company/muza-desarollo"><img src='/logoLinkedin.png'/></a></li>
                    <li className="redSocialItem"><a href="https://www.instagram.com/muza_desarollo/"><img src='/logoInstagram.png'/></a></li>
                    <li className="redSocialItem"><a href="https://www.behance.net/Muzasdc"><img src='/logoBehance.png'/></a></li>
                    <li className="redSocialItem"><a href="https://dribbble.com/Muzasdc"><img src='/logoDribbble.png'/></a></li>
                </ul>

        </footer>
    );
}

export default Footer;