import React from 'react';
import '../components/ProyectoItem2.css';

const ProyectoItem2 = ({rutaImgFondo, titulo, onClick }) => {

    const handleClick = () => {
        onClick(); // Llama a la función de manejo de clics personalizada
    };

    return (
        <article className='proyectoItem'>
            <img className='imgFondo' src={rutaImgFondo} alt={`Imagen del proyecto ${titulo}`} />
            <a href="#" onClick={handleClick}>
                <img className='flechaVerMas' src='/flechaVerMas.png' alt="Ver más" />
            </a>
        </article>
    );
};

export default ProyectoItem2;