
import './App.css';

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './fonts/fonts.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';  
import 'primereact/resources/primereact.min.css';         
import 'primeicons/primeicons.css'; 
import React, { useState } from "react";
import App2 from './contact';
import App3 from './inicio';
import Proyect from './proyect';
import Dashboard from './dashboard';

import { BrowserRouter } from 'react-router-dom';
import { Routes, Route, Link } from 'react-router-dom';
import ScrollToTop from '../src/components/ScrollToTop';

function App() {
  
  return (

<BrowserRouter>
<ScrollToTop />
      <Routes>
        <Route path="/" element={<App3 />} />
        <Route path="/contact" element={<App2 />} />
        <Route path="/proyect" element={<Proyect />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>

    </BrowserRouter>

  );
}

export default App;
