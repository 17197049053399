import { urlbasic } from "./urlService";

export const conteoServicioService = {
    
    conteoServicio() {
        let url = `${urlbasic}api/solicitudes/servicios-conteo`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };
  export const ServiceMoreSoliService = {
    
    ServiceMoreSoliServicio() {
        let url = `${urlbasic}api/servicios/mas-solicitados`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };

  export const ProyectsEndService = {
    
    ProyectsEndServicio() {
        let url = `${urlbasic}api/proyectos/terminados-este-ano`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };
  export const ClientsTypeService = {
    
    clientsTypeServicio() {
        let url = `${urlbasic}api/clientes/conteo-tipo`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };


  //6
  export const CountryCountService = {
    
    CountryCountServicio() {
        let url = `${urlbasic}api/clientes/conteo-pais`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };
  //graficade abajo
  export const ClientscountService = {
    
    clientscountServicio() {
        let url = `${urlbasic}api/clientes/registrados-este-ano`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };

//primera tabla
export const TimeProyectsService = {
    
    timeProyectsServicio() {
        let url = `${urlbasic}api/proyectos/tiempo-duracion`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };
  //segunda tabla
  export const ListClientsSoliService = {
    
    listClientsSoliServicio() {
        let url = `${urlbasic}api/solicitudes/semestre`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };

  //tercera tabla
export const ActiveProyectsService = {
    
    activeProyectsServicio() {
        let url = `${urlbasic}api/proyectos/activos`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };

  //cuarta tabla
export const ProximProyectsService = {
    
    proximProyectsServicio() {
        let url = `${urlbasic}api/proyectos/orden-entrega`;
  
         
        const requestOptions = {
            method: "GET",
            headers: {
               
              'Content-Type': 'application/json'
            }
          };
      
          return fetch(url, requestOptions)
            .then((response) => {
              if (!response.ok) {
                console.log(response)
                throw new Error('Error al obtener los datos');
              }
              return response.json();
            })
            .then((order) => {
              //console.log('Datos del pedido fetch xdd:', order);
              return order
            })
            .catch(error => {
              //console.error('Error al obtener los datos: ', error);
              return null; // Devolver null en caso de error
            });
        }
  };



