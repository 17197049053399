import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import "primeflex/primeflex.css";
import { StyleClass } from 'primereact/styleclass';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './dashboard.css';
import { conteoServicioService, CountryCountService, ProyectsEndService, ServiceMoreSoliService, ClientscountService, ListClientsSoliService, ClientsTypeService, TimeProyectsService, ActiveProyectsService, ProximProyectsService } from './dashboardService';

function Dashboard() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    //variable de primera tabla
    const [products1, setProducts1] = useState([]);//variable de tabla
    //variable de psegunda tabla
    const [products, setProducts] = useState([]);//variable de tabla
    //variable de tercera tabla
    const [products3, setProducts3] = useState([]);//variable de tabla
    //variable de cuarta tabla
    const [products4, setProducts4] = useState([]);//variable de tabla
    //variable de conteo proyectos terminados este dia
    const [countDay, setCountDay] = useState();
    //variable 1 de tipo de clientes
    const [ClientsType, setClientsType] = useState();
    //variable 2 de tipo de clientes
    const [ClientsType2, setClientsType2] = useState();
    //variable para primera grafica depastel  servicios mas solicitados
    const [chartDataPie, setChartDataPie] = useState({});
    const [chartOptionsPie, setChartOptionsPie] = useState({});
    //grafica conteo servicios
    const [chartDataConteoServicio, setChartDataConteoServicio] = useState({});
    const [chartOptionsConteoServicio, setChartOptionsConteoServicio] = useState({});
    //variable para segunda grafica depastel  servicios mas solicitados
    const [chartDataPieCountry, setChartDataPieCountry] = useState({});
    const [chartOptionsPieCountry, setChartOptionsPieCountry] = useState({});
    //grafica de abajo clientes registrados al año
    const [chartDataClientsYear, setChartDataClientsYear] = useState({});
    const [chartOptionsClientsYear, setChartOptionsClientsYear] = useState({});
    useEffect(() => {
        document.body.classList.add('body-dash');
    
        return () => {
          document.body.classList.remove('body-dash');
        };
      }, []);
    useEffect(() => {
        const data = {
            labels: ['Mes 1', 'Mes 2', 'Mes 3', 'Mes 4', 'Mes 5', 'Mes 6', 'Mes 7', 'Mes 8', 'Mes 9', 'Mes 10', 'Mes 11', 'Mes 12'],
            datasets: [
                {
                    label: 'registros de clientes',
                    data: [540, 325, 702, 620, 620, 620, 620,578,345,123,235,876],
                    backgroundColor: [
                        'rgba(255, 159, 64, 0.2)',
                        
                      ],
                      borderColor: [
                        'rgb(255, 159, 64)',
                        
                      ],
                      borderWidth: 1
                }
            ]
        };
        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    //primera grafica de pastel
    useEffect(() => {
        // Realiza la solicitud a la API
    ServiceMoreSoliService.ServiceMoreSoliServicio()
    .then((data) => {
        if (data && data.data) {
            // Procesa los datos recibidos de la API
            const labels = data.data.map(item => item.nombre_servicio);
            const values = data.data.map(item => item.veces_solicitado);

            // Configura los datos para el gráfico
            const documentStyle = getComputedStyle(document.documentElement);
            const chartData = {
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: [
                            documentStyle.getPropertyValue('--blue-500'), 
                            documentStyle.getPropertyValue('--yellow-500'), 
                            documentStyle.getPropertyValue('--green-500')
                        ],
                        hoverBackgroundColor: [
                            documentStyle.getPropertyValue('--blue-400'), 
                            documentStyle.getPropertyValue('--yellow-400'), 
                            documentStyle.getPropertyValue('--green-400')
                        ]
                    }
                ]
            };

            // Configura las opciones del gráfico
            const chartOptions = {
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true
                        }
                    }
                }
            };

            // Actualiza el estado con los datos procesados
            setChartDataPie(chartData);
            setChartOptionsPie(chartOptions);
        }
    })
    
    .catch((error) => {
        console.error('Error al obtener los datos: ', error);
    });
    }, []);
    //segunda grafica de pastel
    useEffect(() => {
        // Realiza la solicitud a la API
    CountryCountService.CountryCountServicio()
    .then((data) => {
        //console.log(data)
        if (data && data.data) {
            // Procesa los datos recibidos de la API
            const labels = data.data.map(item => item.pais_cliente);
            const values = data.data.map(item => item.numero_clientes);

            // Configura los datos para el gráfico
            const documentStyle = getComputedStyle(document.documentElement);
            const chartData = {
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: [
                            documentStyle.getPropertyValue('--blue-500'), 
                            documentStyle.getPropertyValue('--yellow-500'), 
                            documentStyle.getPropertyValue('--green-500')
                        ],
                        hoverBackgroundColor: [
                            documentStyle.getPropertyValue('--blue-400'), 
                            documentStyle.getPropertyValue('--yellow-400'), 
                            documentStyle.getPropertyValue('--green-400')
                        ]
                    }
                ]
            };

            // Configura las opciones del gráfico
            const chartOptions = {
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true
                        }
                    }
                }
            };

            // Actualiza el estado con los datos procesados
            setChartDataPieCountry(chartData);
            setChartOptionsPieCountry(chartOptions);
        }
    })
    
    .catch((error) => {
        console.error('Error al obtener los datos: ', error);
    });
    }, []);
    //grafica de barras del centro conteo de servicios
    useEffect(() => {
        conteoServicioService.conteoServicio()
        .then(response => {
            //console.log(response);
            if (response && Array.isArray(response.data)) {
                // Mapear los datos recibidos para el formato de la gráfica
                const labels = response.data.map(item => item.nombre_servicio);
                const values = response.data.map(item => item.veces_solicitado);

                // Configurar los datos de la gráfica
                const chartData = {
                    labels: labels,
                    datasets: [{
                        label: 'Servicios',
                        data: values,
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    }]
                };

                // Configurar las opciones de la gráfica
                const chartOptions = {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                };

                // Actualizar el estado con los datos de la gráfica
                setChartDataConteoServicio(chartData);
                setChartOptionsConteoServicio(chartOptions);
            } else {
                console.error('Los datos recibidos no son un array:', response.data);
            }
        })
        .catch(error => {
            console.error('Error al obtener los datos: ', error);
        });
    }, []);
    //grafica de barras de abajo
    useEffect(() => {
        ClientscountService.clientscountServicio()
        .then(response => {
            //console.log(response);
            if (response && Array.isArray(response.data)) {
                // Mapear los datos recibidos para el formato de la gráfica
                const labels = response.data.map(item => item.mes);
                const values = response.data.map(item => item.clientes_registrados);

                // Configurar los datos de la gráfica
                const chartData = {
                    labels: labels,
                    datasets: [{
                        label: 'clientes',
                        data: values,
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    }]
                };

                // Configurar las opciones de la gráfica
                const chartOptions = {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                };

                // Actualizar el estado con los datos de la gráfica
                setChartDataClientsYear(chartData);
                setChartOptionsClientsYear(chartOptions);
            } else {
                console.error('Los datos recibidos no son un array:', response.data);
            }
        })
        .catch(error => {
            console.error('Error al obtener los datos: ', error);
        });
    }, []);
    //primer contador
    useEffect(() => {
        ProyectsEndService.ProyectsEndServicio()
            .then((datacount) => {
            setCountDay(datacount.data.proyectos_terminados); // Asigna directamente el objeto recibido
            //console.log("es", countDay);
            })
            .catch((error) => {
            console.error('Error al obtener los datos', error);
            });
    },[]);
    //primer contador
    useEffect(() => {
        ClientsTypeService.clientsTypeServicio()
            .then((datacount) => {
                const [firstClientType, secondClientType] = datacount.data;
    
                setClientsType(firstClientType?.numero_clientes || "no disponible");
                setClientsType2(secondClientType?.numero_clientes || "no disponible");
            })
            .catch((error) => {
                console.error('Error al obtener los datos', error);
            });
    }, []);



    //estos dos useEfect estan relacionados,en ocasiones lo asincrono de las fucniones hace que prodcuts no funcione chido
    useEffect(() => {
        ListClientsSoliService.listClientsSoliServicio()
            .then((datacount) => {
            setProducts(datacount.data); // Asigna directamente el objeto recibido
            //console.log("es", products);
            })
            .catch((error) => {
            console.error('Error al obtener los datos', error);
            });
    },[]);
    useEffect(() => {
        //console.log("products actualizado:", products);
    }, [products]);
    //primera tabla
    //estos dos useEfect estan relacionados,en ocasiones lo asincrono de las fucniones hace que prodcuts no funcione chido
    useEffect(() => {
        TimeProyectsService.timeProyectsServicio()
            .then((datacount) => {
            setProducts1(datacount.data); // Asigna directamente el objeto recibido
            //console.log("es", products1);
            })
            .catch((error) => {
            console.error('Error al obtener los datos', error);
            });
    },[]);
    useEffect(() => {
        //console.log("products actualizado:", products);
    }, [products1]);
    //tercera tabla
    useEffect(() => {
        ActiveProyectsService.activeProyectsServicio()
            .then((datacount) => {
            setProducts3(datacount.data); // Asigna directamente el objeto recibido
            //console.log("es", products3);
            })
            .catch((error) => {
            console.error('Error al obtener los datos', error);
            });
    },[]);
    useEffect(() => {
        //console.log("products actualizado:", products);
    }, [products3]);

     //cuarta tabla
     useEffect(() => {
        ActiveProyectsService.activeProyectsServicio()
            .then((datacount) => {
            setProducts4(datacount.data); // Asigna directamente el objeto recibido
            //console.log("es", products3);
            })
            .catch((error) => {
            console.error('Error al obtener los datos', error);
            });
    },[]);
    useEffect(() => {
        //console.log("products actualizado:", products);
    }, [products4]);




    return (
       <div>
        <div className="grid">
  <div className="col-12 md:col-6 lg:col-3">
    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
      <div className="flex justify-content-between mb-3">
        <div>
          <span className="block text-500 font-medium mb-3">Conteo de proyectos terminados en el año </span>
          <div className="text-900 font-medium text-xl">{countDay}</div>
        </div>
        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
          <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
        </div>
      </div>
      
    </div>
  </div>
  <div className="col-12 md:col-6 lg:col-3">
    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
      <div className="flex justify-content-between mb-3">
        <div>
          <span className="block text-500 font-medium mb-3">Falta un conteo aqui</span>
          <div className="text-900 font-medium text-xl">$2.100</div>
        </div>
        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
          <i className="pi pi-map-marker text-orange-500 text-xl"></i>
        </div>
      </div>
      <span className="text-green-500 font-medium">%52+ </span>
      <span className="text-500">since last week</span>
    </div>
  </div>
  <div className="col-12 md:col-6 lg:col-3">
    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
      <div className="flex justify-content-between mb-3">
        <div>
          <span className="block text-500 font-medium mb-3"> Conteo de los clientes que son personas</span>
          <div className="text-900 font-medium text-xl">{ClientsType}</div>
        </div>
        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
          <i className="pi pi-inbox text-cyan-500 text-xl"></i>
        </div>
      </div>
      
    </div>
  </div>
  <div className="col-12 md:col-6 lg:col-3">
    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
      <div className="flex justify-content-between mb-3">
        <div>
          <span className="block text-500 font-medium mb-3"> Conteo de los clientes que son empresas</span>
          <div className="text-900 font-medium text-xl">{ClientsType2}</div>
        </div>
        <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
          <i className="pi pi-comment text-purple-500 text-xl"></i>
        </div>
      </div>
      
    </div>
  </div>
</div>

<div className="grid mt-4">
<div className="col-12 md:col-6 lg:col-4">
    <div className="card h-full flex flex-col" style={{ width: '100%', height: '100%' }}>
        <h5 style={{ textAlign: 'center' }}>Mostrar id y nombre de los 3 servicios más solicitados en los últimos 3 meses</h5>
        <div className="flex-grow flex items-center justify-center" style={{ width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                <Chart 
                    type="pie" 
                    data={chartDataPie} 
                    options={chartOptionsPie} 
                    style={{ maxWidth: '100%', maxHeight: '100%' }} 
                />
            </div>
        </div>
    </div>
</div>
  <div className="col-12 md:col-6 lg:col-4">
    <div className="card h-full flex flex-col" style={{ width: '100%', height: '100%' }}>
      <h5>Conteo de cuántas veces se ha solicitado cada servicio en una solicitud</h5>
      <div className="flex-grow"  style={{ width: '100%', height: '100%' }}>
        <Chart 
          type="bar" 
          data={chartDataConteoServicio} 
          options={chartOptionsConteoServicio} 
          style={{ width: '100%', height: '100%' }} 
        />
      </div>
    </div>
  </div>
  <div className="col-12 md:col-6 lg:col-4">
    <div className="card h-full flex flex-col" style={{ width: '100%', height: '100%' }}>
        <h5 style={{ textAlign: 'center' }}>Conteo de los clientes de cada país</h5>
        <div className="flex-grow flex items-center justify-center" style={{ width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                <Chart 
                    type="pie" 
                    data={chartDataPieCountry} 
                    options={chartOptionsPieCountry} 
                    style={{ maxWidth: '100%', maxHeight: '100%' }} 
                />
            </div>
        </div>
    </div>
</div>
  <div className="col-12 md:col-6 lg:col-6" >
    <div className="card h-full flex flex-col "  style={{ width: '100%', height: '100%' }}>
    <h5>Listar todos los proyectos terminados y el tiempo en días que tardó en entregarse  </h5>
    <div className="flex-grow" style={{ width: '100%', height: '100%' }}>
      <DataTable value={products1} tableStyle={{ width: '100%' }}>
        <Column field="id_proyecto" header="id del proyecto"></Column>
        <Column field="nombre_proyecto" header="nombre del proyecto"></Column>
        <Column field="duracion_dias" header="duracion del proyecto"></Column>
      </DataTable>
    </div>
    </div>
  </div>
  <div className="col-12 md:col-6 lg:col-6">
    <div className="card h-full flex flex-col"  style={{ width: '100%', height: '100%' }}>
    <h5>Lista de los clientes que han realizado solicitudes en el último semestre y conteo de cuántas solicitudes realizaron en ese periodo  </h5>
    <div className="flex-grow" style={{ width: '100%', height: '100%' }}>
      <DataTable value={products} tableStyle={{ width: '100%' }}>
        <Column field="nombre_cliente" header="nombre del cliente"></Column>
        
        <Column field="solicitudes_realizadas" header="cantidad"></Column>
      </DataTable>
    </div>
    </div>
  </div>
  <div className="col-12 md:col-6 lg:col-6" >
    <div className="card h-full flex flex-col "  style={{ width: '100%', height: '100%' }}>
    <h5>Mostrar el id, nombre, fecha de inicio, fecha de entrega y responsable de proyecto, además del nombre con apellidos del cliente, de los proyectos activos  </h5>
    <div className="flex-grow" style={{ width: '100%', height: '100%' }}>
      <DataTable value={products3} tableStyle={{ width: '100%' }}>
      <Column field="id_proyecto" header="id del proyecto"></Column>
      <Column field="nombre_proyecto" header="nombre del proyecto"></Column>
        <Column field="fecha_ini_proyecto" header="fecha de inicio"></Column>
        <Column field="fecha_entrega_proyecto" header="fecha de entrega"></Column>
        <Column field="responsable_proyecto" header="responsable del proyecto"></Column>
        <Column field="nombre_cliente" header="nombre del cliente"></Column>
        <Column field="ape_pat_cliente" header="apellido paterno"></Column>
        <Column field="ape_mat_cliente" header="apellido materno"></Column>
      </DataTable>
    </div>
    </div>
  </div>
  <div className="col-12 md:col-6 lg:col-6">
    <div className="card h-full flex flex-col"  style={{ width: '100%', height: '100%' }}>
    <h5>Mostrar id, nombre, fecha de inicio y fecha de entrega de los proyectos ordenados por fecha de entrega más próxima </h5>
    <div className="flex-grow" style={{ width: '100%', height: '100%' }}>
      <DataTable value={products4} tableStyle={{ width: '100%' }}>
        <Column field="id_proyecto" header="id del proyecto"></Column>
        <Column field="nombre_proyecto" header="nombre del proyecto"></Column>
        <Column field="fecha_ini_proyecto" header="fecha de inicio"></Column>
        <Column field="fecha_entrega_proyecto" header="fecha de entrega"></Column>
      </DataTable>
    </div>
    </div>
  </div>
  <div className="col-12 md:col-6 lg:col-12">
    <div className="card h-full flex flex-col"  style={{ width: '100%', height: '100%' }}>
    <h5> Conteo de los clientes registrados en cada mes de cada año  </h5>
    <div className="flex-grow" style={{ width: '100%', height: '100%' }}>
        <Chart 
            type="bar" 
            data={chartDataClientsYear} 
            options={chartOptionsClientsYear} 
            style={{ width: '100%', height: '100%' }} 
            />
    </div>
    </div>
  </div>
  
</div>


       </div>
    )



}
export default Dashboard;